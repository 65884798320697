import {useEffect} from "react";
import initAnalytics from '../../helpers/analytics';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import PageAuthenticate from "../PageAuthenticate";
import PageConfirmIdentity from "../PageConfirmIdentity";
import PageVerifyIdentity from "../PageVerifyIdentity";
import PageVerifyDigitalID from "../PageVerifyDigitalID";
import PageError from "../PageError";
import PageSuccess from "../PageSuccess";

const nav = [
  {
    id: "PageAuthenticate",
    path: "/",
    conponent:<PageAuthenticate/>
  },
  {
    id: "PageConfirmIdentity",
    path: "/confirm-identity",
    conponent:<PageConfirmIdentity/>
  },
  {
    id: "PageVerifyIdentity",
    path: "/verify-identity",
    conponent:<PageVerifyIdentity/>
  },
  {
    id: "PageVerifyDigitalID",
    path: "/digital-id",
    conponent:<PageVerifyDigitalID/>
  },
  {
    id: "PageSuccess",
    path: "/success",
    conponent:<PageSuccess/>
  },
  {
    id: "PageError",
    path: "*",
    conponent:<PageError/>
  },
]

export default function App() {
  useEffect(()=>{
    if (!window.digitalData) initAnalytics();
  },[])
  return (
    <Router>
      <Routes>
        {
          nav.map((page, index) => {
            return (
              <Route path={page.path} element={page.conponent} key={index} />
            )
          })
        }
    </Routes>
  </Router>
  );
}
