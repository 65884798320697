// eslint-disable-next-line
import React, { useEffect, useState, useRef } from "react"
import useSWR from "swr"
import {
  filterAuthorisationServers,
  getManualAuthorisationServer
} from "./certification.tsx"
import "./global.css"
import "./Popup.css"
import { TextStyle, ActionButton, Surface, ActionList,ActionListItem,useToggle,ModalDialogConfirmation } from "@able/react"
import CustomSpacing from "../../components/Spacing"
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import { updateAnalyticsData } from '../../helpers/analytics';
import { setSession,removeSessionItem, getSession } from "../../helpers";

export const Popup = ({
  onProceed,
  onCancel,
  claims,
  requiredClaims,
  showAllParticipants,
  participantsUri,
  certificationStatus,
  popupTitle,
  rpDataSharingDescription,
  enableManualVerification,
  requiredCertifications,
  rpLogoUrl,
  logoUrl,
  actionRef
}) => {
  const navigation = useNavigate();
  const [showClaims, setShowClaims] = useState(false) // mobile only
  const [refId, setRefId] = useState(null)
  const [elRef, setElRef] = useState("")
  const [modalIsOpen, toggleModal] = useToggle();
  const { data: participants, error, isLoading } = useSWR(
    participantsUri,
    async () => {
      const response = await fetch(participantsUri)
      if (!response.ok) throw new Error(response.statusText)
      return response.json()
    }
  )
  const popupRef = useRef(null);
  const actionListRef = useRef({});
  // useEffect(() => {
  //   popupRef && popupRef.current && popupRef.current.focus()
  // }, [])

  const claimsOrder = {
    name: 1,
    given_name: 2,
    middle_name: 3,
    family_name: 4,
    birthdate: 5,
    email: 6,
    address: 7,
    phone_number: 8,
    over18: 9,
    over21: 10,
    over25: 11,
    over65: 12,
    beneficiary_account_au: 13,
    beneficiary_account_au_payid: 14,
    beneficiary_account_international: 15
  }

  const claimsDescription = {
    address: "Residential address",
    birthdate: "Date of birth",
    email: "Email address",
    family_name: "Family name",
    given_name: "Given name",
    middle_name: "Middle name",
    name: "Full name",
    phone_number: "Phone number",
    over18: "18+ verification",
    over21: "21+ verification",
    over25: "25+ verification",
    over65: "65+ verification",
    beneficiary_account_au: "Beneficiary account",
    beneficiary_account_au_payid: "PayID",
    beneficiary_account_international: "International account"
  }

  const sortClaims = claims => {
    const sortedClaims = [...claims]
    return sortedClaims.sort(
      (claim1, claim2) => claimsOrder[claim1] - claimsOrder[claim2]
    )
  }

  const handleEnterEsc = event => {
    if (event.key !== "Enter" && event.key !== "Escape") return

    if (event.key === "Escape") {
      onCancel()
      return
    }

    // Enter
    const activeElement = document.activeElement
    if (!activeElement) return

    if (
      activeElement.classList.contains("cid-idp-selector-popup-participant")
    ) {
      activeElement.click()
    }
  }

  const handleOnProceed = (AuthorisationServerId, eventName) => {
    updateAnalyticsData(popupTitle, null, {
      "eventInfo": {
        "eventAction": "click",
        "eventType": "clickTrack",
        "eventCategory": "buttonClick",
        "eventName": eventName
      },
      "attributes":{}
    })
    setElRef(AuthorisationServerId)
    window.scrollTo(0, 0)
    setTimeout(() => {
      toggleModal()
    }, 500);
  }

  // handling click events
  const handleOnClick = (id, eventName) => {
    let eventAction = (id === "continue" || id==="cancel") ? "impression" : "click";
    let eventType = (id === "continue" || id==="cancel") ? "modelContent" : "clickTrack";
    let eventCategory = (id === "continue" || id==="cancel") ? "information" : "buttonClick";
    updateAnalyticsData(popupTitle, null, {
      "eventInfo": {
        "eventAction": eventAction,
        "eventType": eventType,
        "eventCategory": eventCategory,
        "eventName": eventName
      },
      "attributes":{}
    })
      
    if (id === "continue") {
      onProceed(elRef, true)
    }
    if (id === 'cancel') {
      // actionListRef && actionListRef.current[bankData].focus()
      actionListRef && actionListRef.current[elRef].focus()
    }
    if (id === "back") {
      removeSessionItem("cid_store")
      removeSessionItem("par_authorisationServerId")
      removeSessionItem("cid_code")
      removeSessionItem("cid_state")
      removeSessionItem("cid_iss")
      setTimeout(() => {
        navigation("/verify-identity")
      },500)
    }
  }

  const [authorisationServers, setAuthorisationServers] = useState([]);
  const [manualAuthorisationServer, setManualAuthorisationServer] = useState({});
  const [filteredAuthorisationServers, setFilteredAuthorisationServers] = useState(null);
  const [hasOnlyManualAuthorisationServer, setHasOnlyManualAuthorisationServer] = useState(null);

  useEffect(() => {
    if (participants && participants.length > 0) {
      const authorisationServersList = participants && participants
        .map(participant => participant.AuthorisationServers)
        .flat()
        .sort((authServer1, authServer2) =>
          authServer1.CustomerFriendlyName.localeCompare(
            authServer2.CustomerFriendlyName
          )
      )
      setAuthorisationServers(authorisationServersList)

      const manualAuthorisationServerList = getManualAuthorisationServer(
        authorisationServersList,
        showAllParticipants
      )
      setManualAuthorisationServer(manualAuthorisationServerList)
      const authorisationServersWithoutManual = authorisationServersList.filter(
        ({ AuthorisationServerId }) =>
          AuthorisationServerId !==
          manualAuthorisationServerList?.AuthorisationServerId
      )

      const filterByClaims = requiredClaims || claims
      const filteredAuthorisationServersList = filterAuthorisationServers(
        authorisationServersWithoutManual,
        showAllParticipants,
        certificationStatus,
        filterByClaims,
        requiredCertifications
      )
      setFilteredAuthorisationServers(filteredAuthorisationServersList)

      // const hasOnlyManualAuthorisationServer =
      setHasOnlyManualAuthorisationServer(manualAuthorisationServerList && filteredAuthorisationServersList.length === 0)
    }
    
    
  },[participants])

  const renderAuthorisationServers = () => {
    if (isLoading) {
      return (
        <Surface className="white-surface">
          <Loader />
        </Surface>
      )
    }

    if (error) {
      return (
        <Surface className="white-surface" >
          <CustomSpacing
            top="spacing3x"
            bottom="spacing3x"
            left="spacing3x"
            right="spacing3x"
          >
            <TextStyle className="white-surface">
              <TextStyle element="h2" alias="HeadingC">
                Choose a digital ID provider
              </TextStyle>
              <TextStyle element="div" className="infobox">
                <TextStyle element="h4" alias="Label">
                  We were not able to load Identity Providers.
                </TextStyle>
                <TextStyle>Come back to us later.</TextStyle>
              </TextStyle>
            </TextStyle>
          </CustomSpacing>
        </Surface>
      )
    }

    const authorisationServers = participants
      .map(participant => participant.AuthorisationServers)
      .flat()
      .sort((authServer1, authServer2) =>
        authServer1.CustomerFriendlyName.localeCompare(
          authServer2.CustomerFriendlyName
        )
      )

    const manualAuthorisationServer = getManualAuthorisationServer(
      authorisationServers,
      showAllParticipants
    )
    const authorisationServersWithoutManual = authorisationServers.filter(
      ({ AuthorisationServerId }) =>
        AuthorisationServerId !==
        manualAuthorisationServer?.AuthorisationServerId
    )

    const filterByClaims = requiredClaims || claims
    const filteredAuthorisationServers = filterAuthorisationServers(
      authorisationServersWithoutManual,
      showAllParticipants,
      certificationStatus,
      filterByClaims,
      requiredCertifications
    )

    const hasOnlyManualAuthorisationServer =
      manualAuthorisationServer && filteredAuthorisationServers.length === 0

    // if there's only one IDP and it's a manual one (speficic situation for launch - might remove later)
    if (enableManualVerification && hasOnlyManualAuthorisationServer) {
      return (
        <div className="cid-idp-selector-popup-provider-wrapper">
          <div className="cid-idp-selector-popup-manualonly">
            <div className="cid-idp-selector-popup-manualonly-info">
              {/* card icon */}
              <svg
                aria-hidden="true"
                focusable="false"
                className="cid-idp-selector-popup-manualonly-icon"
                width="42"
                height="42"
                viewBox="0 0 42 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_401_12266)">
                  <mask
                    id="mask0_401_12266"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="42"
                    height="42"
                  >
                    <path d="M42 0H0V42H42V0Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_401_12266)">
                    <path
                      d="M30 0H12C5.37258 0 0 5.37258 0 12V30C0 36.6274 5.37258 42 12 42H30C36.6274 42 42 36.6274 42 30V12C42 5.37258 36.6274 0 30 0Z"
                      fill="#EFFBF4"
                    />
                    <path
                      d="M35 9H12C10.3431 9 9 10.3431 9 12V28C9 29.6569 10.3431 31 12 31H35C36.6569 31 38 29.6569 38 28V12C38 10.3431 36.6569 9 35 9Z"
                      fill="#BEEDD2"
                    />
                    <path
                      d="M31.9996 11.8496H8.99961C7.25991 11.8496 5.84961 13.2599 5.84961 14.9996V27.9996C5.84961 29.7393 7.25991 31.1496 8.99961 31.1496H31.9996C33.7393 31.1496 35.1496 29.7393 35.1496 27.9996V14.9996C35.1496 13.2599 33.7393 11.8496 31.9996 11.8496Z"
                      fill="#F9FDFB"
                      stroke="#262626"
                      strokeWidth="1.7"
                    />
                    <path
                      d="M30.5 18H23"
                      stroke="#BEEDD2"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                    <path
                      d="M30.5 21H23"
                      stroke="#BEEDD2"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                    <path
                      d="M30.5 24H23"
                      stroke="#BEEDD2"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                    <path
                      d="M15 21C16.1046 21 17 20.1046 17 19C17 17.8954 16.1046 17 15 17C13.8954 17 13 17.8954 13 19C13 20.1046 13.8954 21 15 21Z"
                      stroke="#262626"
                      strokeWidth="1.2"
                    />
                    <path
                      d="M19 26C19 24.9391 18.5786 23.9217 17.8284 23.1716C17.0783 22.4214 16.0609 22 15 22C13.9391 22 12.9217 22.4214 12.1716 23.1716C11.4214 23.9217 11 24.9391 11 26H19Z"
                      stroke="#262626"
                      strokeWidth="1.2"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_401_12266">
                    <rect width="42" height="42" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <div>
                <h3 className="cid-idp-selector-popup-manualonly-title">
                  You can verify your details with ConnectID using your own
                  identity document.
                </h3>
                <p className="cid-idp-selector-popup-manualonly-description">
                  ConnectID does not collect, hold, or store any personal
                  information at any time.
                </p>
              </div>
            </div>
            <button
              className="cid-idp-selector-popup-manualonly-button"
              onClick={() =>
                onProceed(
                  manualAuthorisationServer.AuthorisationServerId,
                  "Confirm your identity with a document"
                )
              }
              tabIndex={1}
            >
              Confirm your identity with a document
            </button>
          </div>
        </div>
      )
    }

    // there a risk here when there's only manual IDP and manual verification is not enabled -> no providers available
    return (
      <React.Fragment>
        <CustomSpacing top="" bottom="" left="" right="">
          <Surface className="white-surface">
            <React.Fragment>
              <CustomSpacing
                top="spacing3x"
                bottom="spacing3x"
                left="spacing3x"
                right="spacing3x"
              >
                <TextStyle element="div">
                  <TextStyle element="h2" alias="HeadingC">
                    Choose a digital ID provider
                  </TextStyle>
                  <CustomSpacing top="spacing1x" bottom="" left="" right="">
                    <TextStyle>
                      Telstra will only confirm your name and date of birth with
                      your nominated bank. We won't access your banking details
                      or government held information.
                    </TextStyle>
                  </CustomSpacing>
                  <CustomSpacing top="spacing1x" bottom="" left="" right="">
                    {filteredAuthorisationServers.length > 0 ? (
                      <ActionList variant="Hero">
                        {filteredAuthorisationServers.map(
                          (
                            {
                              AuthorisationServerId,
                              CustomerFriendlyLogoUri,
                              CustomerFriendlyName
                            },
                            index
                          ) => (
                            <ActionListItem
                              id={AuthorisationServerId}
                              key={index}
                              logoTilePath={CustomerFriendlyLogoUri}
                              label={CustomerFriendlyName}
                              element='button'
                              events={{ onClick: () => handleOnProceed(AuthorisationServerId, CustomerFriendlyName) }}
                              elementRef={e=>actionListRef.current[AuthorisationServerId]=e}
                            />
                          )
                        )}
                      </ActionList>
                    ) : (
                      <CustomSpacing top="" bottom="" left="" right="">
                        <TextStyle element="div" className="infobox">
                          <TextStyle element="h4" alias="HeadingC">
                            No Identity Providers available.
                          </TextStyle>
                          <CustomSpacing
                            top="spacing2x"
                            bottom=""
                            left=""
                            right=""
                            children=""
                          />
                          <TextStyle>Come back to us later.</TextStyle>
                        </TextStyle>
                      </CustomSpacing>
                    )}
                  </CustomSpacing>
                </TextStyle>
                <CustomSpacing top="spacing3x" bottom="" left="" right="">
                  <TextStyle>
                    ConnectID doesn't collect or store your personal
                    information.
                  </TextStyle>
                </CustomSpacing>
              </CustomSpacing>
            </React.Fragment>
          </Surface>
        </CustomSpacing>
        {enableManualVerification && manualAuthorisationServer && (
          <CustomSpacing top="spacing3x" bottom="" left="" right="">
            <TextStyle>Can't see an ID provider that works for you?</TextStyle>
            <button
              className="cid-idp-selector-popup-manual-action"
              tabIndex={1}
              onClick={() =>
                onProceed(
                  manualAuthorisationServer.AuthorisationServerId,
                  "Confirm your identity with a document"
                )
              }
              data-testid="manual-verification-action"
            >
              Confirm your identity with a document
            </button>
          </CustomSpacing>
        )}
      </React.Fragment>
    )
  }

  const actionList = [
    {id:"1",icon:"https://www.edigitalagency.com.au/wp-content/uploads/Telstra-logo-PNG.png",title:"Bank A"},
    {id:"2",icon:"https://www.edigitalagency.com.au/wp-content/uploads/Telstra-logo-PNG.png",title:"Bank B"},
    {id:"3",icon:"https://www.edigitalagency.com.au/wp-content/uploads/Telstra-logo-PNG.png",title:"Bank C"},
    {id:"4",icon:"https://www.edigitalagency.com.au/wp-content/uploads/Telstra-logo-PNG.png",title:"Bank D"},
  ]

  return (
    <React.Fragment>
      <ModalDialogConfirmation
        isShowing={modalIsOpen}
        className="able-fonts"
        setHideDialog={toggleModal}
        title="Redirect to the bank's website or app"
        description="By clicking 'continue', you'll be redirected to your selected organisation website or app. Telstra does not collect or store your ID document or any login or transaction details from your organisation when verifying via ConnectID."
        stackButtonOnVXS={false}
        confirmButtonLabel='Continue'
        confirmButtonEvents={{ onClick: () => handleOnClick("continue", "Redirect to the bank's website or app - Continue") }}
        cancelButtonLabel='Cancel'
        cancelButtonEvents={{ onClick: () => handleOnClick("cancel", "Redirect to the bank's website or app - Cancel") }}
        developmentUrl={window.location.origin + '/able-sprites.svg'}
      />
      <CustomSpacing top="spacing5x" bottom="spacing5x" left="" right="">
        <div className="page-container">
          <TextStyle element="h1" alias="HeadingB">
            {popupTitle || "Verify with ConnectID"}
          </TextStyle>
        </div>
      </CustomSpacing>
      <div className="bg-gray">
        <CustomSpacing top="spacing5x" bottom="spacing5x" left="" right="">
          <div className="page-container">
            <div className="fullwidth">
              <CustomSpacing top="" bottom="spacing5x" left="" right="">
                <TextStyle element="div" className="top-icon-section">
                  <TextStyle className="cid-idp-selector-popup-logos">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className="cid-idp-selector-popup-logo"
                      viewBox="0 0 132.37 157.84"
                    >
                      <path d="M102.22,91.65c0,19.9-16.13,36.03-36.03,36.03s-36.03-16.13-36.03-36.03,16.13-36.03,36.03-36.03c9.95,0,18.96,4.03,25.48,10.55l40.71-40.71c-6.52-6.52-10.55-15.52-10.55-25.46h-30.14c0,13.45,4.02,25.95,10.92,36.39-10.45-6.9-22.96-10.93-36.42-10.93C29.63,25.47,0,55.1,0,91.65s29.63,66.19,66.19,66.19,66.19-29.63,66.19-66.19h-30.16Z" />
                    </svg>
                  </TextStyle>
                  <TextStyle className="t-able-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </TextStyle>
                  <TextStyle>
                    <img
                      src={logoUrl}
                      alt="Telstra Logo"
                      className="cid-idp-selector-main-logo"
                      aria-hidden="true"
                    />
                  </TextStyle>
                </TextStyle>
              </CustomSpacing>
              <CustomSpacing top="" bottom="spacing4x" left="" right="">
                <Surface className="white-surface">
                  {claims && (
                    <CustomSpacing
                      top="spacing3x"
                      bottom="spacing3x"
                      left="spacing3x"
                      right="spacing3x"
                    >
                      <TextStyle element="h2" alias="HeadingC">
                        Details requested:
                      </TextStyle>
                      <TextStyle element="ul" className="claimsList">
                        {sortClaims(claims).map((claim, index) => (
                          <li className="claimsList-item" key={index}>
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                                fill="#fff"
                              />
                              <path
                                d="M4.81445 8.14121L6.95437 10.2811L11.472 5.76465"
                                stroke="#262626"
                                strokeWidth="1.7"
                                strokeLinecap="round"
                              />
                            </svg>
                            {claimsDescription[claim]}
                          </li>
                        ))}
                      </TextStyle>
                    </CustomSpacing>
                  )}
                </Surface>
              </CustomSpacing>
              {
                // renderAuthorisationServers()
              }
              { filteredAuthorisationServers &&
                <React.Fragment>
                  {
                    filteredAuthorisationServers.length > 0 ?
                    <Surface className="white-surface">
                      <CustomSpacing top="spacing3x" bottom="spacing3x" left="spacing3x" right="spacing3x">
                      <TextStyle element="h2" alias="HeadingC">
                        Choose a digital ID provider
                      </TextStyle>
                      <CustomSpacing top="spacing1x" bottom="" left="" right="">
                        <TextStyle>
                          Telstra will only confirm your name and date of birth with
                          your nominated bank. We won't access your banking details
                          or government held information.
                        </TextStyle>
                      </CustomSpacing>
                      <CustomSpacing top="spacing1x" />
                        <ActionList variant='Hero' topDivider={false}>
                          {
                            filteredAuthorisationServers.map((el, index) => (
                            <ActionListItem
                            id={el.AuthorisationServerId}
                            key={index}
                            logoTilePath={el.CustomerFriendlyLogoUri}
                            label={el.CustomerFriendlyName}
                            element='button'
                            events={{ onClick: () => handleOnProceed(el.AuthorisationServerId,el.CustomerFriendlyName) }}
                            elementRef={e=>actionListRef.current[el.AuthorisationServerId]=e}
                            />
                            ))
                          }
                        </ActionList>
                      </CustomSpacing>
                    </Surface>
                    :
                    <CustomSpacing>
                      <TextStyle element="div" className="infobox">
                        <TextStyle element="h4" alias="HeadingC">
                          No Identity Providers available.
                        </TextStyle>
                        <CustomSpacing top="spacing2x"/>
                        <TextStyle>Come back to us later.</TextStyle>
                      </TextStyle>
                    </CustomSpacing>
                  }
                </React.Fragment>
              }
              
              {enableManualVerification && manualAuthorisationServer && (
                <CustomSpacing top="spacing3x" bottom="" left="" right="">
                  <TextStyle>Can't see an ID provider that works for you?</TextStyle>
                  <button
                    className="cid-idp-selector-popup-manual-action"
                    tabIndex={1}
                    onClick={() =>
                      onProceed(
                        manualAuthorisationServer.AuthorisationServerId,
                        "Confirm your identity with a document"
                      )
                    }
                    data-testid="manual-verification-action"
                  >
                    Confirm your identity with a document
                  </button>
                </CustomSpacing>
              )}
              {
                isLoading ?
                  <Surface className="white-surface">
                    <Loader />
                  </Surface>
                : error ?
                  <Surface className="white-surface" >
                    <CustomSpacing
                      top="spacing3x"
                      bottom="spacing3x"
                      left="spacing3x"
                      right="spacing3x"
                    >
                      <TextStyle className="white-surface">
                        <TextStyle element="h2" alias="HeadingC">
                          Choose a digital ID provider
                        </TextStyle>
                        <TextStyle element="div" className="infobox">
                          <TextStyle element="h4" alias="Label">
                            We were not able to load Identity Providers.
                          </TextStyle>
                          <TextStyle>Come back to us later.</TextStyle>
                        </TextStyle>
                      </TextStyle>
                    </CustomSpacing>
                  </Surface>
                : (enableManualVerification && hasOnlyManualAuthorisationServer) ?
                  <div className="cid-idp-selector-popup-provider-wrapper">
                    <div className="cid-idp-selector-popup-manualonly">
                      <div className="cid-idp-selector-popup-manualonly-info">
                        {/* card icon */}
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          className="cid-idp-selector-popup-manualonly-icon"
                          width="42"
                          height="42"
                          viewBox="0 0 42 42"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_401_12266)">
                            <mask
                              id="mask0_401_12266"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="42"
                              height="42"
                            >
                              <path d="M42 0H0V42H42V0Z" fill="white" />
                            </mask>
                            <g mask="url(#mask0_401_12266)">
                              <path
                                d="M30 0H12C5.37258 0 0 5.37258 0 12V30C0 36.6274 5.37258 42 12 42H30C36.6274 42 42 36.6274 42 30V12C42 5.37258 36.6274 0 30 0Z"
                                fill="#EFFBF4"
                              />
                              <path
                                d="M35 9H12C10.3431 9 9 10.3431 9 12V28C9 29.6569 10.3431 31 12 31H35C36.6569 31 38 29.6569 38 28V12C38 10.3431 36.6569 9 35 9Z"
                                fill="#BEEDD2"
                              />
                              <path
                                d="M31.9996 11.8496H8.99961C7.25991 11.8496 5.84961 13.2599 5.84961 14.9996V27.9996C5.84961 29.7393 7.25991 31.1496 8.99961 31.1496H31.9996C33.7393 31.1496 35.1496 29.7393 35.1496 27.9996V14.9996C35.1496 13.2599 33.7393 11.8496 31.9996 11.8496Z"
                                fill="#F9FDFB"
                                stroke="#262626"
                                strokeWidth="1.7"
                              />
                              <path
                                d="M30.5 18H23"
                                stroke="#BEEDD2"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                              />
                              <path
                                d="M30.5 21H23"
                                stroke="#BEEDD2"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                              />
                              <path
                                d="M30.5 24H23"
                                stroke="#BEEDD2"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                              />
                              <path
                                d="M15 21C16.1046 21 17 20.1046 17 19C17 17.8954 16.1046 17 15 17C13.8954 17 13 17.8954 13 19C13 20.1046 13.8954 21 15 21Z"
                                stroke="#262626"
                                strokeWidth="1.2"
                              />
                              <path
                                d="M19 26C19 24.9391 18.5786 23.9217 17.8284 23.1716C17.0783 22.4214 16.0609 22 15 22C13.9391 22 12.9217 22.4214 12.1716 23.1716C11.4214 23.9217 11 24.9391 11 26H19Z"
                                stroke="#262626"
                                strokeWidth="1.2"
                              />
                            </g>
                          </g>
                          <defs>
                            <clipPath id="clip0_401_12266">
                              <rect width="42" height="42" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <div>
                          <h3 className="cid-idp-selector-popup-manualonly-title">
                            You can verify your details with ConnectID using your own
                            identity document.
                          </h3>
                          <p className="cid-idp-selector-popup-manualonly-description">
                            ConnectID does not collect, hold, or store any personal
                            information at any time.
                          </p>
                        </div>
                      </div>
                      <button
                        className="cid-idp-selector-popup-manualonly-button"
                        onClick={() =>
                          onProceed(
                            manualAuthorisationServer.AuthorisationServerId,
                            "Confirm your identity with a document"
                          )
                        }
                        tabIndex={1}
                      >
                        Confirm your identity with a document
                      </button>
                    </div>
                  </div>
                : null
              }

              <CustomSpacing
                top="spacing4x"
                bottom="spacing1x"
                left=""
                right=""
              >
                <TextStyle element="p" alias="Label">
                  Can't see an ID provider that works for you?
                </TextStyle>
              </CustomSpacing>
              <ActionButton
                developmentUrl={window.location.origin + "/able-sprites.svg"}
                element="button"
                label="Enter your details manually"
                to="/"
                type="button"
                variant="LowEmphasis"
                className="fullwidth"
                events={{
                  onClick: () =>
                    handleOnClick("back", "Enter your details manually")
                }}
              />
            </div>
          </div>
        </CustomSpacing>
      </div>
    </React.Fragment>
  )
}
