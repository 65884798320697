import React from "react";
import { Column, Divider,TextStyle,InlineLink} from "@able/react";
import { updateAnalyticsData } from '../../helpers/analytics';
import CustomSpacing from "../../components/Spacing";
const title = document.title;
function TelstraFooter() {

  let copyright = `Copyright © ${new Date().getFullYear()} Telstra`;
  let privacy_link = "https://www.telstra.com.au/privacy/privacy-statement";
  let termsLink = "https://www.telstra.com.au/terms-of-use";
  const handleOnClick = (eventName) => {
    updateAnalyticsData(title, null, {
      "eventInfo": {
        "eventAction": "click",
        "eventType": "clickTrack",
        "eventCategory": "linkClick",
        "eventName": eventName
      },
      "attributes":{}
    })
  }
  
  return (
    <footer className="footer-comm">
      <Column>
        <Divider/>
        <CustomSpacing top="spacing3x" bottom="spacing3x">
          <InlineLink>
          <TextStyle alias="FinePrint" element="p" className="footer-links">
              {copyright}
              <a className="t-footer-privacy" href={privacy_link} target="_blank" rel="noreferrer" onClick={()=>handleOnClick("Privacy")}>Privacy</a>
              <a className="t-footer-terms" href={termsLink} target="_blank" rel="noreferrer"onClick={()=>handleOnClick("Terms of use")}>Terms of use</a>
          </TextStyle>
          </InlineLink>
        </CustomSpacing>
      </Column>
    </footer>
  )
}

export default React.memo(TelstraFooter);