import { useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageContent from "../PageContent";
import Loader from "../../components/Loader";
import { updateAnalyticsData } from '../../helpers/analytics';
import { validateIDVRequest,IDPSelectorAuthToken } from '../../redux/api';
import { setSession, getSession, removeSessionItem } from "../../helpers";
import { serverErrors, errorRefId } from "../../helpers/messages";
import { saveValidateIDVResponse, setPageLoaded, setCommonError, setConnectIdData } from '../../redux/verifyIdentifySlice';
import { useAuth } from "react-oidc-context";

export default function PageAuthenticate() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [title] = useState("Authenticate");
  let mounted = true;
  const auth = useAuth();
  
  useEffect(() => {
    if (!getSession("loadAuth")) {
      auth.signinRedirect()
    }
    if (mounted && getSession("loadAuth")) {
      if(!getSession("cid_store")) {
        validateIDVRequestCall()
      }
      if (getSession("cid_store") && Object.keys(getSession("cid_store")).length > 0) {
        runIDPSelectorAuthToken()
      }
    }
    mounted = false;
  }, [getSession("loadAuth")])

  // updating analytics on every event within the document
  const updateAnalyticsDataFunc = (code,eventName) => {
    updateAnalyticsData(title, null, {
      "eventInfo": {
        "eventAction": "error",
        "eventType": "Technical",
        "eventCategory": code,
        "eventName": eventName
      },
      "attributes": {}
    })
  }

  const validateIDVRequestCall = () => {
    dispatch(setPageLoaded(false))
    validateIDVRequest()(dispatch)
    .then((response) => {
      if (response.status === 200) {
        dispatch(saveValidateIDVResponse(response.data))
        setSession("clientCode",response.data.clientCode)
        setSession("cid_store",false)
        navigation("/confirm-identity");
      } else {
        const eKey = "validateIDVRequest";
        const err = "err_" + response.status + (response.errors ? "_" + response.errors[0].code : "");
        const refCode = errorRefId.prefix + errorRefId.delimeter + errorRefId[eKey] + serverErrors[eKey][err].errRefNo;
        dispatch(setCommonError({ ...serverErrors[eKey][err], refCode }))
        updateAnalyticsDataFunc(err,serverErrors[eKey][err].errDesc)
        if (serverErrors[eKey][err] && serverErrors[eKey][err].redirectTo === "gotoUrl") {
          window.location = getSession("gotoUrl");
        } else {
          setSession("cid_store",false)
          navigation("/error");
        }
      }
    })
    .catch((error) => {
      setSession("cid_store",false)
      navigation("/error");
    });
  }
  const runIDPSelectorAuthToken = () => {
    const cid_code = sessionStorage.getItem("cid_code");
    const cid_state = sessionStorage.getItem("cid_state");
    const cid_iss = sessionStorage.getItem("cid_iss");
    const eKey = "cidParToken";
    dispatch(setPageLoaded(false));
    if (cid_code && cid_state && cid_iss) {
      IDPSelectorAuthToken(cid_code , cid_state, cid_iss)(dispatch)
        .then((response) => {
        if (response.message || !response.status || response.status===401){
          setSession("tokenExpired",true)
        } else {
          setSession("tokenExpired",false)
        }
        if (response.status === 200) {
          dispatch(setConnectIdData(response.data));
          window.location = getSession("gotoUrl");
        } else {
          const err = "err_" + response.status + (response.errors ? "_" + response.errors[0].code : "");
          const refCode = errorRefId.prefix + errorRefId.delimeter + errorRefId[eKey] + serverErrors[eKey][err].errRefNo;
          dispatch(setCommonError({ ...serverErrors[eKey][err], refCode }))
          updateAnalyticsDataFunc(err,serverErrors[eKey][err].errDesc)
          if (serverErrors[eKey][err] && serverErrors[eKey][err].redirectTo === "gotoUrl") {
            window.location = getSession("gotoUrl");
          } else {
            setSession("isCIDError",true);
            navigation("/confirm-identity");
          }
        }
        sessionStorage.removeItem("cid_code")
        sessionStorage.removeItem("cid_state")
        sessionStorage.removeItem("cid_iss")
      })
      .catch((error) => {
        setSession("isCIDError",true);
        navigation("/confirm-identity");
      });
    }else{
      // setSession("cid_store",false);
      validateIDVRequestCall()
    }
  }
  
  return (
    <PageContent title={title}>
      <Loader />
    </PageContent>
  );
}