import React,{useState,useEffect,useRef} from "react";
import PageContent from "../PageContent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateAnalyticsData } from '../../helpers/analytics';
import { serverErrors, errorRefId } from '../../helpers/messages';
import { TextStyle,ModalDialogConfirmation, useToggle } from "@able/react";
import { setSession,removeSessionItem, getSession } from "../../helpers";
import config from "../../helpers/config";
import Loader from "../../components/Loader";
import { CidButton } from './CidButton.js';
import CustomSpacing from "../../components/Spacing";
import { IDPSelectorAuth } from '../../redux/api';
import { setPageLoaded } from "../../redux/verifyIdentifySlice.js";
import redLogo from './../../assets/redLogo.svg';
const title = "Verify with ConnectID";

export default function PageVerifyDigitalID() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [actionRef, setActionRef] = useState("");
  const { validateIDVResponse,connectIdData,isPageLoaded } = useSelector((state) => state.verifyIdentify);
  const claimsList = ["given_name", "middle_name", "family_name","birthdate"];
  
  useEffect(() => {
    setTimeout(() => { setIsLoading(false) }, 1000)
    if (isPageLoaded) {
      navigation("/")
    }
  },[])

  const handleOnProceed = (authorisationServerId, loader=false) => {
    setSession("par_authorisationServerId", authorisationServerId)
    setIsLoading(loader)
    runIDPSelectorAuth()
  }

  const runIDPSelectorAuth = () => {
    const payload = {
      "authorisationServerId": getSession("par_authorisationServerId"),
      "essentialClaims": claimsList,
      "scanType": "Digital",
      "referenceId": validateIDVResponse.referenceId,
      "clientCode": validateIDVResponse.clientCode,
      "voluntaryClaims": [],
      "purpose": "verifying your identity"
    }
    dispatch(setPageLoaded(false));
    IDPSelectorAuth(payload)(dispatch)
    .then((response) => {
      if (response.message || !response.status || response.status === 401) {
        setSession("tokenExpired", true);
      } else {
        setSession("tokenExpired", false)
      }
      if (response.status === 200) {
        let sessionStore = {
          authorisationServerId:response.data.authorisationServerId,
          codeVerifier:response.data.codeVerifier,
          iss:response.data.iss,
          nonce:response.data.nonce,
          state:response.data.state,
          referenceId:response.data.referenceId
        }
        setSession("cid_store", sessionStore)
        window.location = response.data.authUrl
      } else {
        const eKey = "cidCreatePar";
        let err = null;
        let refCode = null;
        if(response.status === 500){
          err = "err_default";
        } else {
          err = "err_" + response.status + (response.errors ? "_" + response.errors[0].code : "");
        }
        refCode = errorRefId.prefix + errorRefId.delimeter + errorRefId["cidPar"] + serverErrors[err].errRefNo;
        dispatch(setCommonError({...serverErrors[eKey][err], refCode}))
        setSession("isCIDError",true);
        navigation("/confirm-identity");
      }
    })
    .catch((error) => {
      setSession("isCIDError",true);
      navigation("/confirm-identity");
    });
  }

  return (
    <PageContent title={title} type="connectID">
      {isLoading ? <Loader /> :
        <div>
          {
            Object.keys(connectIdData).length > 0 ?
            <CustomSpacing bottom="spacing4x">
              <TextStyle alias="HeadingB">ConnectID verification done successfully</TextStyle>
              <CustomSpacing top="spacing4x" />
              <TextStyle>authName: { connectIdData.authName }</TextStyle>
              <TextStyle>verificationId: { connectIdData.verificationId }</TextStyle>
              <TextStyle>overallResult: { connectIdData.overallResult }</TextStyle>
              <TextStyle>referenceId: { connectIdData.referenceId }</TextStyle>
            </CustomSpacing>
            :
            <React.Fragment>
              <CidButton
                onProceed={(authorisationServerClicked,eventName) => handleOnProceed(authorisationServerClicked,eventName)}
                claims={claimsList} participantsUri={config.cidParticipants}
                title={title} logoUrl={redLogo}
              />
            </React.Fragment>
          }
        </div>
      }
    </PageContent>
  );
}
