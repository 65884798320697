import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Column,
  TextStyle,
  ActionButton,
  Surface,
  Divider,
  InlineLink,
  Lozenge,
  Accordion,
  AccordionItem,
  MessageSection,
  MessageInline
} from "@able/react";
import CustomSpacing from "../../components/Spacing";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PageContent from "../PageContent";
import Loader from "../../components/Loader";
import PageTitle from "../../components/PageTitle";
import { updateAnalyticsData } from '../../helpers/analytics';
import { getSession, removeSessionItem, setSession } from "../../helpers";
import icon1 from "../../assets/icon-1.png";
import icon2 from "../../assets/icon-2.svg";
import GlobalBanner from "../../components/GlobalBanner";
import { clientErrors } from "../../helpers/messages";
const privacy_link = "https://www.telstra.com.au/privacy#info-collect";
const alternate_link = "https://www.telstra.com.au/support/account-payment/id-check-for-new-account";
const contact_link = "https://www.telstra.com.au/contact-us";
const visit_connect_link = "https://connectid.com.au/";


export default function PageConfirmIdentity() {
  const navigation = useNavigate();
  const titleRef = useRef(null);
  const idUnavialableRef = useRef(null);
  const [title] = useState("Confirm your identity");
  const [isLoading, setIsLoading] = useState(true);
  const [isCIDShowError, setCIDShowError] = useState(getSession("isCIDError"));
  const { commonError,isPageLoaded } = useSelector((state) => state.verifyIdentify);
  useEffect(() => {
    //added condition for refreshing Confirm Identity page 
    if (isPageLoaded) {
      navigation("/");
    }
    if (getSession("cid_store") && Object.keys(getSession("cid_store")).length > 0) {
        setSession("cid_store",false);
    }
    if (isCIDShowError && getSession("cid-error")) {
      if(getSession("cid-error") === clientErrors.cid_login_cancel
      || getSession("cid-error") === clientErrors.cid_aborted_cancel
      || getSession("cid-error") === clientErrors.cid_declined_cancel){
        setCIDShowError(false);
      }
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [])
  
  useEffect(() => {
    if (isCIDShowError) {
      updateAnalyticsData(title, null, {
        "eventInfo": {
          "eventAction": "error",
          "eventType": "Technical",
          "eventCategory": "cid-error",
          "eventName": getSession("cid-error")
        },
        "attributes":{}
      })
    }
  },[isCIDShowError])
  
  // handling analytics updation
  const handleAnalytics = (id, eventName, btnType) => {
    let eventCategory = btnType ? btnType : "buttonClick";
    updateAnalyticsData(title, null, {
      "eventInfo": {
        "eventAction": "click",
        "eventType": "clickTrack",
        "eventCategory": eventCategory,
        "eventName": eventName
      },
      "attributes":{}
    })
  }
  // handling click events
  const handleOnClick = (id, eventName, btnType) => {
    setSession('isCIDError',false);
    setSession('cid-error',"");
    handleAnalytics(id,eventName, btnType);
    if (id === "connectID") {
      console.log("connectID button clicked")
      // setTimeout(() => {
      //   navigation("/digital-id",{ replace: true });
      // }, 500);
    }
    if (id === 'manual') {
      setIsLoading(true)
      setTimeout(() => {
        navigation("/verify-identity",{ replace: true});
      }, 500);
    }
  }

  return (
    <PageContent title={title}>
      {isLoading ? <Loader />
        :
        <Grid padding={false}>
          <Column cols={12}>
          <PageTitle ref={titleRef} title={title} focusTitle={true} />
          <div ref={idUnavialableRef} role="alert">
            <GlobalBanner pageCID = {true} />
          </div>
          <div role="alert">
            {
              isCIDShowError &&
              <CustomSpacing bottom="spacing3x" top="spacing3x">
                <MessageInline
                developmentUrl={window.location.origin + '/able-sprites.svg'} variant="Error">
                  <TextStyle alias="Label">We are unable to verify your identity via ConnectID</TextStyle>
                    <CustomSpacing top="spacing1x" />
                  <TextStyle>Try another bank or verify manually with your ID document</TextStyle>
                </MessageInline>
              </CustomSpacing>
            }
            </div>
            <CustomSpacing top="spacing1x" bottom="spacing3x">
              <TextStyle>You can verify your identity via one of the following:</TextStyle>
            </CustomSpacing>
            <CustomSpacing bottom="spacing3x">
              <Lozenge label='Option 1' variant='Neutral'/>
            </CustomSpacing>
            <TextStyle alias="HeadingC" element="h2">Verify with ConnectID</TextStyle>
            <CustomSpacing top="spacing1x" bottom="spacing3x">
              <TextStyle>
                ConnectID is a simple way to verify your identity using the information (such as your name and date of birth) held by an organisation you already trust. We will not ask for any documents.
              </TextStyle>
            </CustomSpacing>
            <Surface
              background="materialBaseSecondary"
              cornerRadius="cornerRadiusSoft"
              variant="SurfaceFlat"
            >
              <CustomSpacing
                bottom="spacing2x"
                left="spacing3x"
                right="spacing3x"
                top="spacing2x"
              >
                <React.Fragment key=".0">
                  <TextStyle className="t-text-center">Digital ID providers you can use:</TextStyle>
                  <TextStyle className="t-text-center images-group">
                    <img src={icon1} alt="Commonwealth Bank"/>
                    <img src={icon2} alt="National Australia Bank (NAB)"/>
                  </TextStyle>
                </React.Fragment>
              </CustomSpacing>
            </Surface>
            <CustomSpacing top="spacing3x" bottom="spacing3x">
              <button
                className="fullwidth able-ActionButton able-ActionButton--high-emphasis cid-idp-selector-button"
                onClick={() =>
                  handleOnClick("connectID", "Verify with ConnectID")
                }
              >
                <svg viewBox="0 0 132.37 157.84" aria-hidden="true" focusable="false" >
                  <path d="M102.22,91.65c0,19.9-16.13,36.03-36.03,36.03s-36.03-16.13-36.03-36.03,16.13-36.03,36.03-36.03c9.95,0,18.96,4.03,25.48,10.55l40.71-40.71c-6.52-6.52-10.55-15.52-10.55-25.46h-30.14c0,13.45,4.02,25.95,10.92,36.39-10.45-6.9-22.96-10.93-36.42-10.93C29.63,25.47,0,55.1,0,91.65s29.63,66.19,66.19,66.19,66.19-29.63,66.19-66.19h-30.16Z"></path>
                </svg>
                Verify with ConnectID
              </button>
            </CustomSpacing>
            <Accordion variant="Contained">
              <AccordionItem
                developmentUrl={
                  window.location.origin + "/able-sprites.svg"
                }
                label="What's ConnectID?"
                titleElement="h3"
                events={{
                  onClick: () => {
                    handleAnalytics("", "What's ConnectID?","clickAccordion")
                  },
                }}
              >
                <TextStyle>
                    ConnectID is an Australian-owned digital identity solution from Australian Payments Plus, allowing you to simply prove who you are using participating banks you already know. 
                </TextStyle>
                <CustomSpacing top="spacing3x" bottom="spacing1x">
                  <TextStyle>
                    ConnectID doesn't see or store any personal information at any time.
                  </TextStyle>
                </CustomSpacing>
                <InlineLink>
                  <TextStyle>
                    <a href={visit_connect_link} target="_blank" rel="noreferrer" >Visit ConnectID website for more details</a>
                  </TextStyle>
                </InlineLink>
              </AccordionItem>
              <AccordionItem
                developmentUrl={
                  window.location.origin + "/able-sprites.svg"
                }
                label="How does ConnectID work?"
                titleElement="h3"
                events={{
                  onClick: () => {
                    handleAnalytics("", "How does ConnectID work?","clickAccordion")
                  },
                }}
              >
                <TextStyle element="ul">
                  <li>Select your preferred bank. </li>
                  <li>You'll be redirected to your bank's website or app to authenticate yourself.</li>
                  <li>Review the information being requested and give your consent to share.</li>
                  <li>Once your verification is complete, you'll be redirected back to Telstra.</li>
                </TextStyle>
                <CustomSpacing top="spacing3x" bottom="spacing1x">
                  <TextStyle>
                  Telstra will only confirm your name and date of birth with your nominated bank. We won't access any bank details, transaction information, or card numbers.
                  </TextStyle>
                </CustomSpacing>
              </AccordionItem>
            </Accordion>
            <CustomSpacing top="spacing1x">
              <TextStyle alias="FinePrint">
                  If you don't have an account with any of these banks, you can verify your identity manually.
              </TextStyle>
            </CustomSpacing>
            <CustomSpacing top="spacing4x" bottom="spacing5x">
              <Divider/>
            </CustomSpacing>
            <Lozenge label='Option 2' variant='Neutral' />
            <CustomSpacing top="spacing3x" bottom="spacing1x">
            <TextStyle alias="HeadingC" element="h2">Verify manually </TextStyle>
            </CustomSpacing>
            <TextStyle>
              Can't find your bank listed above? You can verify your identity manually. By choosing to do so, you allow Telstra to collect and store your full name, date of birth, and ID details.
            </TextStyle>
            <CustomSpacing top="spacing3x" bottom="spacing3x">
              <ActionButton
                developmentUrl={
                  window.location.origin + "/able-sprites.svg"
                }
                element="button"
                label="Enter your ID details"
                to="/"
                type="button"
                className="fullwidth"
                variant="MediumEmphasis"
                onClick={() =>
                  handleOnClick("manual", "Enter your ID details")
                }
              />
            </CustomSpacing>
            <TextStyle>ID documents we accept: </TextStyle>
            <CustomSpacing top="spacing3x" bottom="spacing3x" left="spacing2x">
              <TextStyle element="ul">
                <li>Australian Driver's Licence</li>
                <li>Australian or International Passport </li>
                <li>Medicare card</li>
                <li>ImmiCard</li>
              </TextStyle>
            </CustomSpacing>
            <InlineLink>
              <TextStyle alias="FinePrint">
              Telstra is collecting your personal information in order to comply with its regulatory obligations, including Australian Communications and Media Authority (ACMA) rules on ID checks for prepaid mobile services. Information about how we collect, store, use and share personal information can be found in our <a href={privacy_link} target="_blank" rel="noreferrer" >Privacy Statement</a>.
              </TextStyle>
            </InlineLink>
            <CustomSpacing top="spacing3x">
              <InlineLink>
                <TextStyle alias="FinePrint">
                Having trouble verifying your ID? You can verify your identity in store using <a href={alternate_link} target="_blank" rel="noreferrer" >alternative forms of ID</a> or <a href={contact_link} target="_blank" rel="noreferrer" >Contact us</a>.
                </TextStyle>
              </InlineLink>
            </CustomSpacing>
          </Column>
        </Grid>
      }
    </PageContent>
  );
}
