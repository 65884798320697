
import React, { useEffect, useState } from 'react';
import {TextStyle} from "@able/react";
import CustomSpacing from "../../components/Spacing";
const Loader = () => {
  return(
    <div className="server-spinner-box">
      <div>
        <div className="round-spinner_new">
          <svg className="t-loader__spinner1" width="30px" height="30px" viewBox="0 0 70 70"
            xmlns="http://www.w3.org/2000/svg">
            <circle stroke="#1A5199" strokeWidth="8" className="path" fill="none" cx="35" cy="35" r="30"></circle>
          </svg>
          <svg className="t-loader__spinner2" width="30px" height="30px" viewBox="0 0 70 70"
            xmlns="http://www.w3.org/2000/svg">
            <circle stroke="#0099F8" strokeWidth="8" className="path" fill="none" cx="35" cy="35" r="30"></circle>
          </svg>
          <svg className="t-loader__spinner3" width="30px" height="30px" viewBox="0 0 70 70"
            xmlns="http://www.w3.org/2000/svg">
            <circle stroke="#1A5199" strokeWidth="8" className="path" fill="none" cx="35" cy="35" r="30"></circle>
          </svg>
        </div>
        <CustomSpacing top="spacing3x"/>
        <TextStyle role="status" alias="Base" element='p'>Loading...</TextStyle>
      </div>
    </div>
  )
}

export default Loader;