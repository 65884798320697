import device from 'current-device';
import iswebview from 'is-webview';

const referrer = document.referrer;

/**
 * getEnv
 *
 * Return deployment environment
 *
 */
export const getEnv = host => {
  const devEnvs = /sit./;
  const uatEnvs = /fn1.|dev./;
  const fn2Envs = /fn2./;
  const localEnvs = /localhost|127.0.0.1/;
  const fn3Envs = /fn3./;

  if (host.match(devEnvs))
    return 'devel';
  else if (host.match(uatEnvs))
    return 'uat';
  else if (host.match(fn2Envs))
    return 'fn2';
  else if (host.match(fn3Envs))
    return 'fn3';
  else if (!host.match(localEnvs))
    return 'production';
  else  
    return 'local';
};

/**
 * deviceType
 *
 * Return device type string for analytics
 *
 */
export const deviceType = (dev, win = window) => {
  const UA = win.navigator.userAgent.toLowerCase();
  if (dev.mobile())
  if (dev.ios())
      return iswebview(UA) ? 'ios-webview' : 'mobile';
    else if (dev.android())
      return iswebview(UA) ? 'android-webview' : 'mobile';
    else
      return 'mobile';
  else
    return dev.type;
};



/**
 * 
 * Initialize analytics data object 
 * 
 */

const initialAnalyticsData = {
  page: {
    "pageInfo": {
      "pageName": 'Verify your identity',
      "destinationURL": window.location.href,
      //"referringURL": referrer,
      "sysEnv": deviceType(device),
      "deployEnv": getEnv(window.location.host),
    },
    "category":{
      "primaryCategory":"identity",
      "secondaryCategory":"idv",
      "pageType":"service"
    },
    "attributes":{
      "division":"TD",
      "subDivision":"TR",
      "subDivisionUnit":"TR"
    },
    "user": [
      {
        "profile": [
          {
            "profileInfo": {
              "loggedInUsing": "Telstra ID: b2c-idcheck"
            }
          }
        ]
      }
    ]
  },
}

/**
 * initAnalytics
 *
 * Bind default analytics data object to window
 *
 */
const initAnalytics = (win = window, dev = device) => {
  if (getEnv(win.location.host)){
    return (win.digitalData = initialAnalyticsData);
  }
};

/**
 * pageChange
 *
 * Update/replace the pageInfo on rcreateDynamicErrorObjectoute changes
 * Push the event data and error code when error occured.
 *
 */
export const pageChange = (step, win = window) => {
  initialAnalyticsData.page.pageInfo.pageName = step;
  initialAnalyticsData.page.pageInfo.destinationURL = win.location.href;
  if("errorMessage" in initialAnalyticsData.page)
    delete initialAnalyticsData.page.errorMessage;
  if("event" in initialAnalyticsData)  
    delete initialAnalyticsData.event;
  return (win.digitalData = initialAnalyticsData);
};

/**
 * 
 * @param {* Page Title} step 
 * @param {* errorMessage} data 
 * @param {* analytics event object} eventData 
 * @param {* window} win 
 */
export const updateAnalyticsData = (step, data, eventData = null, win = window) => {
  const analytics = win.digitalData;
  analytics.page.pageInfo.pageName = step;
  analytics.page.pageInfo.destinationURL = win.location.href;
 
  //Error Message
  if(data){
    const errData = {
      "errorCode": data.code,
      "message": data.msg,
      "type":data.type?data.type:"Validation"
    }
   
    if("errorMessage" in analytics.page)
      analytics.page.errorMessage.push(errData);
    else
      analytics.page.errorMessage = [errData];
  }

  if(!("event" in analytics))    
    analytics.event = [];
  
  if(eventData){
    analytics.event.push(eventData);
  }else{
    analytics.event.push(
      {
        "eventInfo":{
          "eventAction":"error"
        },
        "attributes":{}
      }
    )
  }
  return (win.digitalData = analytics);
}

/**
 * Prepare a global error object to send to analytics code
 * 
 * @param {*Error Code} errCode 
 * @param {*Error Message} errMsg 
 */
export const prepareAnalyticsErrorObj = (errCode, errMsg) => {
  return {
    code : errCode,
    msg : errMsg
  };
}

export default initAnalytics;
